import React, { useEffect } from 'react';
import anime from 'animejs';

import './index.scss';
import { useSetState } from 'ahooks';

function PartTwo(props) {

  const { isInViewport, scrollY } = props;

  const [animationExecuted, setAnimationExecuted] = useSetState({
    top: false,
    bottom: false
  });

  useEffect(() => {
    const viewNode = document.querySelector('.voyage-part-two-title');
    const isInView = isInViewport(viewNode);
    if(animationExecuted.top || !isInView) return;
    setAnimationExecuted({ top: true });
    let tl = anime.timeline({
      easing: 'linear'
    });
    tl.add({
      targets: '.voyage-part-two-title .part-title-wrap .part-cicle',
      opacity: [0, 0.5],
      easing: 'linear',
      duration: 500
    }).add({
      targets: '.voyage-part-two-title .part-title, .voyage-part-two-title .part-subtitle',
      translateY: [-100, 0],
      opacity: [0, 1],
      easing: 'linear',
      duration: 500
      // delay: anime.stagger(100) // 子元素依次出现
    }).add({
      targets: '.voyage-part-two-right .part-cicle',
      opacity: [0, 0.5],
      easing: 'easeInExpo',
      duration: 500
    }).add({
      targets: '.voyage-part-two-right .part-title, .voyage-part-two-right .right-subtitle',
      translateX: [200, 0],
      opacity: [0, 1],
      easing: 'linear',
      duration: 200
      // delay: anime.stagger(200) // 子元素依次出现
    }).add({
      targets: document.querySelectorAll('.voyage-part-two-left .part-two-item'),
      translateX: (el, i) => {
        let translate =  [-270, 20];
        if(i === 0) {
          translate = [-270, 40];
        }
        if(i === 1) {
          translate = [270, 98];
        }
        return translate;
      },
      opacity: [0, 1],
      duration: 1250,
      easing: 'easeOutExpo',
      delay: anime.stagger(200) // 每个元素之间的延迟
    });
  }, [scrollY]);

  useEffect(() => {
    const viewNode = document.querySelector('.voyage-part-two-bottom');
    const isInView = isInViewport(viewNode);
    if(animationExecuted.bottom || !isInView) return;
    setAnimationExecuted({ bottom: true });
    anime({
      targets: document.querySelectorAll('.voyage-part-two-bottom .part-two-bottom-item .element-item'),
      translateY: (el, i) => {
        return el.classList.contains('part-icon') ? [-200, 0] : [200, 0];
      },
      opacity: [0, 1],
      easing: 'easeOutExpo',
      delay: anime.stagger(100) // 每个元素之间的延迟
    });
  }, [scrollY]);

  return (
    <div className="voyage-part voyage-part-two">
      <div className="voyage-part-two-title  dis-flex flex-center">
        <div className="part-title-wrap">
          <div className="part-title text-center">
            <div className="part-cicle" />
            开启出版业数智口碑大时代 <br />助力行业长效经营
          </div>
          <div className="part-subtitle">Launch the Era of Word-of-Mouth Marketing Powered by Digital Intelligence<br />To Boost Sustainable Publishing Success</div>
        </div>
      </div>
      <div className="voyage-part-two-left dis-flex">
        <div className="part-two-list dis-flex col-flex">
          {
            HOME_DATE.TWO_PART.map((item, index) => {
              return (
                <div className="part-two-item dis-flex" key={index}>
                  <img className="part-two-item-icon item-icon" src={item.icon} />
                  <div className="part-two-item-dec ">
                    <div className="item-title mb10">{item.title}</div>
                    <div className="f12 gray-65">{item.subtitle}</div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="voyage-part-two-right">
          <div className="right-subtitle">后流量时代+AIGC时代+渠道分散化时代</div>
          <div className="part-title">
            <div className="part-cicle" />
            如何破局<br />出版单位<br />营销发行难题?
          </div>
        </div>
      </div>
      <div className="voyage-part-two-bottom dis-flex">
        {
          HOME_DATE.THTRR_PART.map((item, index) => {
            return (
              <div key={index} className="dis-flex col-flex flex-center part-two-bottom-item flex1">
                <img className="part-icon element-item" src={item.icon} alt="" />
                <div className=" element-item text-center">
                  <div className="part-title item-title ">{item.title}</div>
                  <div className="item-subtitle text-center gray-65">{item.subtitle}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

PartTwo.defaultProps = {};

export default PartTwo;

const HOME_DATE = {
  TWO_PART: [
    {
      title: 'AIGC赋能高质量内容',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/2949fd0c02c870a8cd0a5f542b04362a.png',
      subtitle: <span>AIGC深度赋能，专业运营团队高效创作，<br />爆款内容随时随地高效生成</span>
    },
    {
      title: '四位一体 渠道矩阵',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/80af3262ad8968d85a9320d481baacc9.png',
      subtitle: <span>官、垂、泛、引；打造自有新媒体渠道矩阵;<br />价格可控，长效稳定</span>
    },
    {
      title: '一站式图书营销策划',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/c1368745f2abb50c6cf63ea0b6551de7.png',
      subtitle: <span>资深运营专家团队，提供专业图书新媒体策划，<br />书书有着落、本本是主角</span>
    }
  ],
  THTRR_PART: [
    {
      title: '更专注',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/c2255d09416f0c6791208644a5da8e50.png',
      subtitle: '聚焦后流量时代、AIGC时代、渠道分散化时代的出版行业营销发行难题，打造专注出版行业的新媒体图书营销解决方案。'
    },
    {
      title: '更高效',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/ee8bb2eaa12b73cc0dd3737dad1ed2b3.png',
      subtitle: 'AIGC深度赋能，全面助力出版单位在图书营销发行各环节提质增效，效率可达到传统出版社数倍。'
    },
    {
      title: '更智能',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/08efd3222589956614cdf8311d011cd1.png',
      subtitle: '借助动态大数据、大算力中心，通过出版行业垂类AI大模型BOOKSGPT深度赋能打造多类型爆款内容。'
    },
    {
      title: '更专业',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/a64a19417353e2e8f20224b24902eaa7.png',
      subtitle: '专业运营团队精准定位，提供图书营销策略及营销节奏，打造具有吸引力与情感共鸣的多类型爆款内容，随时随地打造爆款畅销书。'
    },
    {
      title: '更便捷',
      icon: 'https://oss.5rs.me/oss/uploadfe/png/ab7510ad56555743e8d843c484520489.png',
      subtitle: '一体化营销-推广转化链路，一站式新媒体营销组合拳，一键生成多维度分析报告，时刻把握图书营销方向，以流量撬动销量，助力持续提升转化。'
    }
  ]
};