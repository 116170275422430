
export const theme = 'snow'; // snow | bubble

export const placeholder = '请输入内容';

export const modules = {
  toolbar: [
    [{ 'header': [false, 1, 2, 3, 4] }],

    [{ 'size': ['12px', '14px', '16px', '18px', '24px', '32px'] }],

    ['bold'], // ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: [] }],

    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],

    [{ color: [] }, { background: [] }],

    ['image'], // ['link', 'image', 'video'],

    ['clean']
  ],
  clipboard: {
    matchVisual: false
  }
};

export const formats = [
  'header',
  'size',
  'bold',
  // 'italic', 'underline', 'strike', 'blockquote',
  'align', 'list', 'indent',
  'color', 'background',
  'image', // 'link', 'image', 'video',
  'clean'
];

export const useQuillImage = (quill) => {
  // Insert Image(selected by user) to quill
  if(!quill) return null;

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  // Upload Image to Image Server such as AWS S3, Cloudinary, Cloud Storage, etc..
  const saveToServer = async (file) => {
    const body = new FormData();
    body.append('file', file);

    const res = await fetch('Your Image Server URL', { method: 'POST', body });
    insertToEditor(res.uploadedImageUrl);
  };

  // Open Dialog to select Image File
  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  return quill.getModule('toolbar').addHandler('image', selectLocalImage);

};

export default {
  theme,
  modules,
  formats,
  placeholder,
  useQuillImage
};

