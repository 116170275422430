import React, { useEffect, useRef } from 'react';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // snow 主题
import 'react-quill/dist/quill.bubble.css'; // bubble 主题

import QuillConfig  from './useQuilljs';

import './index.scss';

// 自定义字体
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['12px', '14px', '16px', '18px', '24px', '32px'];
Quill.register(SizeStyle, true);

export default function OEditor(props) {

  const editor = useRef(null);

  const { className, initialValue, onChange, ...restProps } = props;

  function handleChange(...values) {
    const [content] = values;
    // console.log(values, content, 'values');
    onChange && onChange(content);
  }

  useEffect(() => {
    // console.log(editor.current, 'QuillEditor');
  }, [editor]);

  return (
    <ReactQuill
      ref={editor}
      className={`QEditor ${className}`}
      theme={QuillConfig.theme}
      defaultValue={initialValue}
      onChange={handleChange}
      placeholder={QuillConfig.placeholder}
      modules={QuillConfig.modules}
      formats={QuillConfig.formats}
      {...restProps}
    />
  );
}

/** props https://github.com/zenoamaro/react-quill **/
OEditor.defaultProps = {
  className: '',
  initialValue: '',
  style: { height: '300px' }
};
