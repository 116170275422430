import Utils from '@/utils/utils';

export const PAGES = {
  HOME: '/adviser/home',
  LOGIN: '',
  CASE: '/adviser/case'
};

// 获取带params路由
export function getPageUrl(page, params) {
  let url = page;
  Object.keys(params)
    .forEach(key => {
      if(url.indexOf(`:${key}`) > -1) {
        url = url.replace(`:${key}`, params[key]);
        delete params[key];
      }
    });
  return `${url}${Utils.formatQuery2QueryStr(params)}`;
}

export default PAGES;

