import zhCN from 'antd/lib/locale/zh_CN';
import 'dayjs/locale/zh-cn';

const THEM_CONSTANTS = {
  primary: '#5156be',
  lightPrimary: '#B1B4ED',
  white: '#FDFDFF',
  transparent: 'transparent',
  radiusBase: 4,
  defaultSpace: 0,
  validateMessages: {
    required: `'${name}' 是必选字段`
  }
};

export default {
  locale: zhCN,
  prefixCls: 'wtdc',
  // rootClassName: '',
  theme: {
    token: {
      // Seed Token，影响范围大
      colorInfo: THEM_CONSTANTS.primary,
      colorLink: THEM_CONSTANTS.primary,
      colorPrimary: THEM_CONSTANTS.primary,
      colorPrimaryText: THEM_CONSTANTS.primary,
      colorTextDisabled: THEM_CONSTANTS.white,
      colorBgContainerDisabled: THEM_CONSTANTS.lightPrimary,
      colorBgTextHover: THEM_CONSTANTS.transparent,
      colorBgTextActive: THEM_CONSTANTS.transparent,
      borderRadius: THEM_CONSTANTS.radiusBase,
      paddingLG: THEM_CONSTANTS.defaultSpace,
      Form: {
        itemMarginBottom: THEM_CONSTANTS.defaultSpace,
        validateMessages: THEM_CONSTANTS.validateMessages
      },
      Button: {
        borderColorDisabled: THEM_CONSTANTS.transparent,
        textHoverBg: THEM_CONSTANTS.transparent
      },
      DatePicker: {
        cellBgDisabled: 'rgba(0, 0, 0, 0.1)'
      }
    }
  }
};
