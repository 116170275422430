import React from 'react';
import classNames from 'classnames';

import ReactQuill from './index';

export default function FormEditor(props) {

  const { className, fieldProps } = props;

  const QuillEditor =  ({ value, onChange }) => {
    return (
      <ReactQuill
        className={classNames({ [`${className}-editor`]: className })}
        initialValue={value}
        onChange={onChange}
        {...fieldProps}
      />
    );
  };

  return (
    <QuillEditor />
  );
}

FormEditor.defaultProps = {
  className: '',
  initialValue: ''
};
