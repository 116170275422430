/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */

import React, { useImperativeHandle } from 'react';

import { Form as _Form } from 'antd';

import { COMMON_FIELDS } from './fields';

import './index.scss';

// Field 类型
export const FieldType = Object.keys(COMMON_FIELDS);

const _renderField = (field) => {
  const { type = 'input', fieldProps, ...itemProps } = field;
  // 渲染 FormItem 类型, fieldProps 参数将作为props传递给基础组件
  if(FieldType.includes(type)) {
    return (
      <_Form.Item {...itemProps}>
        { COMMON_FIELDS[type]({ ...fieldProps }) }
      </_Form.Item>
    );
  } else {
    console.error(`${type} does not exist`);
  }
};

function renderDependencyField({ dependencyValue, ...itemProps }) {
  return (
    <_Form.Item noStyle shouldUpdate>
      {
        _renderField(itemProps)
      }
    </_Form.Item>
  );
}

function renderDefineField(props) {
  const { component: Component, fieldProps, ...itemProps } = props;
  // 自定义组件需考虑读写两种状态 proProps.mode | text
  // const componentProps = {
  //   onChange,
  //   ...itemProps
  // };
  return (
    <_Form.Item {...itemProps}>
      <Component />
    </_Form.Item>
  );
}

function renderField(fieldItem) {
  const { dependencyValue, component, ...itemProps } = fieldItem;
  // 依赖表单
  if(Array.isArray(dependencyValue)) {
    return renderDependencyField(fieldItem);
  }

  // 自定义表单
  if(component) {
    return renderDefineField(fieldItem);
  }
  // Form 表单
  return _renderField(itemProps);
}

export default function FormRender(props) {

  const { parentRef, className, fields, children, ...restProps } = props;

  // form 实例
  const [form] = _Form.useForm();

  // getForm就是暴露给父组件的方法
  // 外部获取 parentRef = useRef();
  useImperativeHandle(parentRef, () => form);

  function renderForm(itemProps) {
    const { options } = itemProps;
    // 多类型
    if(Array.isArray(options)) {
      return (
        <_Form.Group {...itemProps}>
          {
            options.map(fieldItem => renderField(fieldItem))
          }
        </_Form.Group>
      );
    }
    return renderField(itemProps);
  }

  return (
    <_Form form={form} className={className} {...restProps}>
      {
        fields.map(fieldItem => {
          return (
            <React.Fragment key={fieldItem.name}>
              { renderForm(fieldItem) }
            </React.Fragment>
          );
        })
      }
      { children }
    </_Form>
  );
}

FormRender.defaultProps = {
  className: '',
  fields: [], // fields extend ITEM_PROPS
  children: null
};

// fieldProps 基础组件 props
export const FormField = renderField;

// form
export const Form = _Form;

