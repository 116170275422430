import React, { useState, useRef } from 'react';
import { LeftOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import { useRequest, useDebounceFn, useUnmount } from 'ahooks';
import demandapi from '@/api/demandapi';
import commonapi from '@/api/common';
import Button from '@/components/Button';
import Message from '@/components/Message';
import FormRender, { FormField } from '@/components/FormRender';
import md5 from 'md5';
import { regs } from '@/utils/formValidations';

import './index.scss';

function Password(props) {

  const { setFormType, FORM_TYPE } = props;

  const formRef = useRef(null);
  const timer = useRef(null);

  const { loading, runAsync: changePassword } = useRequest(demandapi.retrieve, {
    manual: true
  });

  /**state  state部分**/
  const [values, setValues] = useState({});

  const [sending, setSending] = useState(false);
  const [timing, setTiming] = useState(60);

  function onChange(value, type) {
    const { ...newValues } = values;
    newValues[type] = value;
    setValues(newValues);
  }

  const { run: handleSubmit } = useDebounceFn(() => {
    const { accountPassword, password, ...restValues } = values;
    if(!values.smsCode) return Message.error('请填写验证码!');
    if(Object.keys(values).length) {
      // 校验验证码
      commonapi.validateMessage({
        phone: values.accountPhone,
        validateCode: values.smsCode
      }).then(() => {
        changePassword({ accountPassword: md5(accountPassword), password: md5(password), ...restValues }).then(() => {
          Message.success('密码修改成功！');
          formRef.current.resetFields();
          setFormType(FORM_TYPE.login);
        });
      });
    } else {
      Message.success('信息填写有误！');
    }
  }, { wait: 500 });

  const sendMessage = async () => {
    if(sending) return;
    const phone = formRef.current.getFieldValue('accountPhone');
    formRef.current.validateFields(['accountPhone']);
    if(!regs.mobile.test(phone)) return Message.error('请输入正确格式手机号');
    commonapi.sendPhone({
      phone
      // phoneAreaCode: '+86',
      // systemCode: config.systemCode
    });
    let timing = 59;
    setSending(true);
    setTiming(timing);
    timer.current = setInterval(() => {
      setTiming(timing--);
      if(timing <= 0) {
        clearInterval(timer.current);
        setSending(false);
      }
    }, 1000);
  };

  useUnmount(() => {
    if(timer.current) {
      clearInterval(timer.current);
    }
  });

  return (
    <div className="Password dis-flex col-flex">
      <div className="panel-head dis-flex">
        <LeftOutlined className="cursor" onClick={() => setFormType(FORM_TYPE.login)} />
        <span className="panel-head-title">重置密码</span>
      </div>
      <FormRender className="panel-form dis-flex col-flex" parentRef={formRef} onFinish={handleSubmit}>
        <FormField
          colon={false}
          className="panel-form-wrap"
          name="accountPhone"
          label="手机号"
          rules={[{ required: !!values.accountPhone }]}
          fieldProps={{
            className: 'panel-form-input',
            placeholder: '请输入手机号',
            onChange: (value => onChange(value, 'accountPhone'))
          }}
        />
        <FormField
          colon={false}
          className="panel-form-wrap"
          name="smsCode"
          label="验证码"
          rules={[{ required: !!values.smsCode }]}
          fieldProps={{
            className: 'panel-form-input',
            placeholder: '请输入验证码',
            maxLength: 4,
            suffix: <span className="cursor" onClick={sendMessage}>{sending ? `${timing}s` : '获取验证码'}</span>,
            onChange: (value => onChange(value, 'smsCode'))
          }}
        />
        <FormField
          colon={false}
          type="password"
          className="panel-form-wrap"
          name="password"
          label="设置新密码"
          rules={[
            {
              required: !!values.password,
              pattern: regs.password,
              message: '密码为8-20位数字、大小写字母组合'
            }
          ]}
          fieldProps={{
            autoComplete: 'new-password',
            className: 'panel-form-input',
            placeholder: '请输入密码',
            iconRender: visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />),
            onChange: (value => onChange(value, 'password'))
          }}
        />
        <FormField
          type="password"
          colon={false}
          className="panel-form-wrap"
          name="accountPassword"
          label="确认密码"
          rules={[
            {
              required: !!values.accountPassword,
              pattern: regs.password,
              message: '两次输入密码不一致',
              validator: (options, value) => {
                // 自定义密码校验规则，这里假设密码需要包含8-20位数字、大小写字母组合
                if(!regs.password.test(value)) {
                  return Promise.reject('密码需包含8-20位数字、大小写字母组合');
                } else {
                  const password = formRef.current.getFieldValue('password');
                  if(password !== value) {
                    return Promise.reject('两次输入密码不一致');
                  }
                }
                return Promise.resolve();
              }
            }
          ]}
          fieldProps={{
            autoComplete: 'new-password',
            className: 'panel-form-input',
            placeholder: '请确认密码',
            iconRender: visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />),
            onChange: (value => onChange(value, 'accountPassword'))
          }}
        />
        <Button
          className="panel-form-password_button linear-btn"
          onClick={() => formRef.current.submit()}
          type="primary"
          block
          loading={loading}
        >确定</Button>
      </FormRender>
    </div>
  );
}

Password.defaultProps = {};

export default Password;
