/**
 *  Date    : 2019/11/7
 *  Author  : Weilin
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import demandapi from '@/api/demandapi';
import Utils from '@/utils/utils';

import setPermission from './menus';
import * as Cookie from '@/utils/cookie';
import Message from '@/components/Message';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {
      permissionMenus: [],
      flatPermissionMenus: []
    },
    partyNameList: [],
    memberAccountList: {},
    roleList: [],
    labelList: [],
    memberBaseInfo: {},
    industryCaseInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/update/updateUserInfo',
        field: 'userInfo',
        promise: demandapi.getUserInfo()
          .then(data => {
            const { permissionVOList, partyId, ...userInfo } = data;
            return Object.assign(data, {
              permissionMenus: setPermission(userInfo),
              flatPermissionMenus: Utils.flatMap(setPermission(userInfo))
            });
          })
      };
    },
    // 登录
    login(payload) {
      return {
        promise: demandapi.login(payload)
      };
    },

    logout() {
      return {
        promise: new Promise(() => {
          // 直接退出系统
          demandapi.logout().then(() => {
            Message.success('登出成功');
            window.location.href = window.location.origin;
            Cookie.saveCookie('');  // 清除token
          });
        })
      };
    }
  }
};
