import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import classNames from 'classnames';

export default function IconFont(props) {
  const { type, className, ...restProps } = props;

  const IconFont = createFromIconfontCN({
    scriptUrl: defaultProps.iconfontURL,
    extraCommonProps: Object.assign(
      { className: `${className}` },
      defaultProps.extraCommonProps
    )
  });

  return <IconFont className={classNames(`ic`, className)} type={`ic-${type}`} {...restProps} />;
}

const defaultProps = {
  iconfontURL: ['//at.alicdn.com/t/c/font_4666742_4665pe2qwca.js'], // string | array : 对应项目字体图标库 js 地址
  extraCommonProps: {
    className: ''
  } // Icon 组件设置额外的属性
};
