import React, { useEffect, useRef, useState } from 'react';
import anime from 'animejs';

import Image from '@/components/Image';
import ModalBase from '@/components/Modal';

import LoginForm from '../../LoginForm';

import './index.scss';

function PartOne(props) {

  const [animationExecuted, setAnimationExecuted] = useState(false);

  const [visible, setVisible] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    if(animationExecuted) return;
    setAnimationExecuted(true);
    let tl = anime.timeline({
      easing: 'easeOutExpo',
      delay: 5000,
      complete: () => {
        setVisible(true);
        videoRef.current.muted = true;
        videoRef.current.play();
      }
    });
    tl.add({
      targets: document.querySelectorAll('.voyage-part-one-right .video-image'),
      rotateY: [90, 0],
      easing: 'easeOutCubic',
      delay: anime.stagger(200) // 每个元素之间的延迟
    }).add({
      targets: document.querySelectorAll('.voyage-part-one-left .part-one-item'),
      translateX: [270, 0],
      opacity: [0, 1],
      delay: anime.stagger(100), // 每个元素之间的延迟
      easing: 'easeOutExpo'
    }, '-=1500');
  }, [window.scrollY]);

  const onPlay = () => {
    setVisible(true);
    videoRef.current.muted = false;
    videoRef.current.play();
  };

  const onClose = () => {
    videoRef.current.pause();
    setVisible(false);
  };

  return (
    <div className="voyage-part voyage-part-one">
      <div className="voyage-part-content dis-flex">
        <div className="voyage-part-one-left">
          <div className="text-primary part-title part-color part-one-item part-slogan">专注出版行业的</div>
          <div className="part-title-img part-one-item">
            <div className="part-cicle" />
            <Image width="100%" src="https://oss.5rs.me/oss/uploadfe/png/1dc32a89aba4bb10581c021b241e0cda.png" />
          </div>
          <div className="part-subtitle part-one-item">A New Media Marketing Solution Focused <br /> on Books</div>
          <LoginForm key={3} className="part-one-item" />
        </div>
        <div className="voyage-part-one-right" id="videoPanel">
          <Image className="part-one-play cursor" onClick={onPlay} src="https://oss.5rs.me/oss/uploadfe/png/5730a0848a034d5bfaa147b8416a4398.png" />
          <ModalBase
            title=""
            // getContainer={() => document.getElementById('videoPanel')}
            className="videoModal"
            footer={null}
            open={visible}
            width="80vw"
            centered
            // closable={false}
            onCancel={onClose}
          >
            <video
              ref={videoRef}
              autoPlay
              preload="auto"
              width="100%"
              className="part-one-play cursor"
              controls
              controlsList="nodownload"
            >
              <source src="https://oss.5rs.me/oss/transcode/video/mp4/4b2c4526dab3c1663a581c98031571de_H.264_1920x1080_AAC_2100.mp4" type="video/mp4" />
            </video>
          </ModalBase>
          <div className="part-one-video dis-flex">
            {
              IMAGS.map((item, index) => {
                return (
                  <Image className="video-image" key={index} src={item} width={166} />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

PartOne.defaultProps = {};

export default PartOne;

// const TABS = [
//   {
//     title: '业务价值'
//   },
//   {
//     title: '产品功能'
//   },
//   {
//     title: '最佳实践'
//   },
//   {
//     title: '服务支持'
//   }
// ];

const IMAGS = [
  'https://oss.5rs.me/oss/uploadfe/png/54345cf5fa2c83998dca40851ab85559.png',
  'https://oss.5rs.me/oss/uploadfe/png/5ffe113e4b7b8be5eb9e3ee8479518f9.png',
  'https://oss.5rs.me/oss/uploadfe/png/abd23cc1a99983095099dd3856914be3.png',
  'https://oss.5rs.me/oss/uploadfe/png/3dfda9cd0c425975ed15085a6e2705f3.png',
  'https://oss.5rs.me/oss/uploadfe/png/888e6430e882162acaf39444f6b05616.png',
  'https://oss.5rs.me/oss/uploadfe/png/1a8074dd0e87286911b9fc14558e9d4d.png'
];
