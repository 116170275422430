/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */

import React from 'react';
import { useSetState } from 'ahooks';
import classNames from 'classnames';
import moment from 'moment';
import { DatePicker as DatePickerPack, TimePicker as TimePickerPack } from 'antd';

import Label from '@/components/Label';

const format = 'YYYY-MM-DD HH:mm:ss';

function DatePicker(props) {

  const { title, showTime, className } = props;

  const [state, setState] = useSetState({
    startTime: undefined,
    endTime: undefined
  });

  function disabledDate(startTime) {
    if(!startTime || !state.endTime) return false;
    return startTime.valueOf() > state.endTime.valueOf();
  }

  function onChange(key, value) {
    setState({ [key]: value });
  }

  /**render**/

  return (
    <Label className={classNames(`date-label`, className)} title={title}>
      <DatePickerPack
        className="DatePicker"
        disabledDate={disabledDate}
        showTime={showTime}
        format={format}
        defaultValue={state.startTime ? moment(state.startTime) : ''}
        placeholder="开始时间"
        onChange={onChange}
      />
    </Label>
  );
}

export function RangePicker(props) {

  const { title, format = 'YYYY-MM-DD HH:mm:ss', className, ...restProps } = props;

  return (
    <Label className={classNames(`date-range-label`, className)} title={title}>
      <DatePickerPack.RangePicker format={format} className="RangePicker" {...restProps} />
    </Label>
  );
}

export function TimePicker(props) {

  const { title, showTime, className } = props;

  return (
    <Label className={classNames(`date-time-label`, className)} title={title}>
      <TimePickerPack className="TimePicker" showTime={showTime} />
    </Label>
  );
}

export default DatePicker;
