/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */
import React from 'react';

import { Modal } from 'antd';

import { NOOP } from '@/constants/common';

import './index.scss';

function ModalBase(props) {

  const { className, title, trigger, submitter, onFinish, ...restProps } = props;

  return (
    <Modal className={`ModalBase ${className}`} {...restProps} title={title}>
      { props.children }
    </Modal>
  );
}

ModalBase.info = Modal.info;

ModalBase.success = Modal.success;

ModalBase.error = Modal.error;

ModalBase.warning = Modal.warning;

ModalBase.confirm = Modal.confirm;

ModalBase.useModal = Modal.useModal;

export default ModalBase;

// submitter 包括 resetButtonProps、submitButtonProps、searchConfig、render(props, defaultDom)
// visible 结合 onVisibleChange 通过外部控制 modal
ModalBase.defaultProps = {
  title: '自定义',
  width: '',
  className: '',
  onFinish: NOOP,
  trigger: null, // 弹窗触发元素
  submitter: {
    searchConfig: {
      submitText: '确认',
      resetText: '取消'
    }
  },
  modalProps: {}
};
