import Immutable from 'seamless-immutable';

import defaultSetting from '@/constants/setting';

const { MIN_SIDEBAR_WIDTH, SIDE_BAR_WIDTH, HEADER_HEIGHT, SIDE_BAR_HIDDEN, MIN_PADDING_BOTTOM } = defaultSetting;

export default {
  namespace: 'projectSetting',
  state: Immutable({
    sideBarWidth: SIDE_BAR_WIDTH,
    miniBarWidth: MIN_SIDEBAR_WIDTH,
    headerHeight: HEADER_HEIGHT,
    defaultHidden: SIDE_BAR_HIDDEN,
    paddingBottom: MIN_PADDING_BOTTOM,
    hasFixBottom: false,
    miniBar: false,
    hasTab: false,
    containerPadding: true
    // tabHeight: 50,
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    toggleSmallType: ((state, { data }) => {
      return state.merge({
        miniBar: data.miniBar,
        sideBarWidth: data.miniBar ? MIN_SIDEBAR_WIDTH : SIDE_BAR_WIDTH
      });
    })
  },
  actions: {
    toggleSmallType(miniBar) {
      return {
        type: 'projectSetting/toggleSmallType',
        data: {
          miniBar
        }
      };
    },
    updateContainerPadding(padding) {
      return {
        type: 'projectSetting/update/containerPadding',
        field: 'containerPadding',
        data: padding
      };
    },
    updateLayoutFixBottom(isShow) {
      return {
        type: 'projectSetting/update/hasFixBottom',
        field: 'hasFixBottom',
        data: isShow
      };
    }
  }
};
