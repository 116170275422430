/*
 * @Author: Paco
 * @Date: 2018-11-23 20:39:54
 * @LastEditTIme: Paco
 * @Description:
 */
import Axios from 'axios';
import { message } from 'antd';
import shortid from '@wtdc/shortid';

import { BASE_API_ROOT } from '@/config';
import * as Cookie from '@/utils/cookie';

const axios = Axios.create();

axios.defaults.baseURL = BASE_API_ROOT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.token = Cookie.getCookie();

/**
 * 添加ie缓存清理
 * @params config
 * @returns {*}
 */
const addUidForIe = function(config) {
  if(config.method === 'get') {
    if(!config.params) {
      config.params = {};
    }
    config.params.t = shortid();
  }
  return config;
};

/**
 * 添加token
 * @params config
 */
const addToken = (config) => {
  config.headers = config.headers || {};
  const cookie = Cookie.getCookie();
  if(cookie) {
    config.headers.token = cookie;
  }
  return config;
};

const formatResponse = (response) => {
  const { data } = response;
  if(data.errCode === 0) {
    return data.data;
  } else {
    if(data.message) {
      message.error(data.message);
    }
    return Promise.reject(data);
  }
};

const ERROR_CODE = {
  SERVER_ERROR: 500,
  NETWORK_ERROR: 502,
  AUTHORIZE_ERROR: 401
};

const formatAjaxError = ({ response = {}}) => {
  const { status, data } = response;
  const error = {
    message: data || '服务器内部错误，请稍后重试！',
    status: status
  };
  if(typeof data === 'object' && typeof data.error === 'string') {
    error.message = data.error;
  }
  if(status === ERROR_CODE.NETWORK_ERROR) {
    error.message = '网络问题，请稍后重试！';
  } else if(status === ERROR_CODE.AUTHORIZE_ERROR) {
    Cookie.saveCookie('');  // 清除token
    error.message = '登录已过期，请重新登录！';
    window.setTimeout(() => {
      window.location.href = window.location.origin;
    }, 500);
  }
  if(error.message) {
    message.error(error.message);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(addUidForIe, console.error);
axios.interceptors.request.use(addToken, console.error);

//Add a response interceptor
axios.interceptors.response.use(formatResponse, formatAjaxError);

export default function axiosServices(root) {
  return (method, route) => (data) => {
    const payload = method === 'get' ? { params: data } : data;
    return axios[method](
      `${root}/${route}`,
      payload
    );
  };
}