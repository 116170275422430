import axiosServices from './request';

const Convert = axiosServices('/convert/v1.0');
const MessageApi = axiosServices('/message/v1.0');

export default {

  // check 文件
  checkTick: Convert('get', 'leafFile/checkTick'),

  // upload file
  updateFinish: Convert('post', 'leafFile/updateFinish'),

  // 发送手机短信
  sendMessage: MessageApi('post', 'shortMessage/sendPhoneMessageVerity'),
  /** 验证手机号和验证码 */
  validateMessage: MessageApi('post', 'shortMessage/validate'),
  // 重置密码时,发送验证码
  sendPhone: MessageApi('post', 'shortMessage/sendPhone')

};
