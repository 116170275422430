/**
 * Date    : 2021/11/27
 * Author  : WeiLin
 * Declare : index
 */
import { Button } from 'antd';

import './index.scss';

export default Button;
