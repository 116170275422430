/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */

import React from 'react';
import { Radio } from 'antd';

import Utils from '@/utils/utils';

export default function RadioGroup(props) {

  const { options, textKey, valueKey, radioType, onChange, ...restProps } = props;

  function handleChange(e) {
    onChange && onChange(e.target.value);
  }

  return (
    <Radio.Group {...restProps} onChange={handleChange}>
      {
        options.map((ops, index) => {
          const { option = {}, optionProps } = Utils.handlerOptions(ops, textKey, valueKey);
          let Component = Radio;
          if(radioType && Radio[radioType]) {
            Component = Radio[radioType];
          }
          return (
            <Component key={index} className="radio" value={option[valueKey]} {...optionProps}>
              {option[textKey]}
            </Component>
          );
        })
      }
    </Radio.Group>
  );
}

RadioGroup.defaultProps = {
  options: [],
  textKey: 'title',
  valueKey: 'value'
};
