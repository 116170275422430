import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import Login from './login';
import THEME_CONFIG from '../assets/theme/theme';

import './App.scss';

export default class App extends React.PureComponent {

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    return (
      <ConfigProvider {...THEME_CONFIG}>
        <Switch>
          { this.props.children || <Login /> }
        </Switch>
      </ConfigProvider>
    );
  }
}
