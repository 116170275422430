/**
 *  Date    : 20220209
 *  Author  : weiLin
 *  Declare : eventEmitter
 */

import EventEmitter from '@wtdc/eventemitter';

export default new EventEmitter();

export { EventEmitter };

/** EventEmitter事件名 */
export const EVENT_NAMES = {
  FILE_EVENT: 'FILE_EVENT'
};
