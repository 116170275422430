import { Upload, InputNumber, DatePicker, message } from 'antd';

// 初始化加载
message.config({
  top: 30,
  duration: 2,
  maxCount: 1
});

Upload.__hasLoad = true;
DatePicker.__hasLoad = true;
InputNumber.__hasLoad = true;
