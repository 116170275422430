import axiosServices from './request';

const DemandApi = axiosServices('demand/v1.0');

export default {

  // 用户选择图书类别列表
  bookCategoryList: DemandApi('get', 'userBookMarketing/bookCategoryList'),

  // 用户删除历史营销记录
  deleteDemand: DemandApi('post', 'userBookMarketing/deleteDemand'),

  //用户查看图书营销进度
  demandProgress: DemandApi('get', 'userBookMarketing/demandProgress'),

  //  用户查看历史营销记录
  demandRecords: DemandApi('get', 'userBookMarketing/demandRecords'),

  // 用户发起图书营销需求
  launchDemand: DemandApi('post', 'userBookMarketing/launchDemand'),

  // 修改登录密码
  changePassword: DemandApi('post', 'userAccount/changePassword'),

  // 修改登录密码 post /userAccount/retrieve
  retrieve: DemandApi('post', 'userAccount/retrieve'),

  // 用户提交留资
  consult: DemandApi('post', 'userAccount/consult'),

  // 查看用户信息
  getUserInfo: DemandApi('get', 'userAccount/info'),

  // 用户账号密码登录
  login: DemandApi('post', 'userAccount/login'),

  // 用户账号密码登录
  logout: DemandApi('post', 'userAccount/logout'),

  // 忘记密码找回账号
  retrieveAccount: DemandApi('post', 'userAccount/retrieve'),

  // 修改账号关联信息
  updateAccount: DemandApi('post', 'userAccount/update'),

  // 修改账号关联信息
  exportFiles: DemandApi('post', 'userBookMarketing/exportFiles'),

  // 获取导出的模块交付物
  getExportedModuleFiles: DemandApi('get', 'userBookMarketing/getExportedModuleFiles'),

  //提交导出模块交付
  submitExportModuleFiles: DemandApi('post', 'userBookMarketing/submitExportModuleFiles'),

  //用户图书营销需求前置校验
  checkDemandName: DemandApi('post', 'userBookMarketing/checkDemand')
};
