import React, { useState } from 'react';
import { LeftOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { useRequest  } from 'ahooks';
import md5 from 'md5';

import Input from '@/components/FormRender/Input';
import Checkbox from '@/components/FormRender/Checkbox';
import PAGE from '@/constants/page';
// import config from '@/config';
import * as Cookie from '@/utils/cookie';
import Message from '@/components/Message';
import Button from '@/components/Button';
import demandapi from '@/api/demandapi';
import { useHistory } from '@/utils/router';
import { regs } from '@/utils/formValidations';

import './index.scss';

function Login(props) {

  const { setFormType, FORM_TYPE, type } = props;

  const history  = useHistory();

  /**state  state部分**/
  const [values, setValues] = useState({});

  const { loading, runAsync: login } = useRequest(demandapi.login, {
    manual: true
  });

  function onChange(value, type) {
    const { ...newValues } = values;
    newValues[type] = value;
    setValues(newValues);
  }

  async function handleSubmit() {
    const { accountPhone, accountPassword, rememberFor7days = false } = values;
    if(!regs.mobile.test(accountPhone)) {
      return Message.error('请输入正确的手机号');
    }
    if(accountPhone && accountPassword) {
      login({ rememberFor7days, accountPhone, accountPassword: md5(accountPassword) }).then(({ token }) => {
        Cookie.saveCookie(token);
        Message.success('登录成功');
        history.replace(PAGE.HOME);
      }).catch(({ message }) => {
        Message.error(message);
      });
    } else {
      Message.error('账号不存在');
    }

  }

  return (
    <div className="Login dis-flex col-flex">
      <div className="panel-head dis-flex">
        {!type && <LeftOutlined className="cursor" onClick={() => setFormType(FORM_TYPE.registry)} /> }
        <span className="panel-head-title">账号登录</span>
      </div>
      { values.agentName && <div className="panel-agent text-center">XXXXX出版社</div> }
      <div className="panel-form dis-flex col-flex">
        <div className="panel-form-wrap dis-flex col-flex">
          <span className="f16">手机号</span>
          <Input className="panel-form-input" placeholder="请输入手机号" onChange={(value => onChange(value, 'accountPhone'))} />
        </div>
        <div className="panel-form-wrap dis-flex col-flex">
          <span className="f16">密码</span>
          <Input.Password
            // autoComplete="new-password"
            className="panel-form-input"
            onChange={(value => onChange(value, 'accountPassword'))}
            placeholder="请输入密码"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </div>
        {
          Boolean(1) &&
            <div className="panel-form-actions dis-flex flex-justify">
              <Checkbox className="text-gray3" onClick={(e => onChange(e.target.checked, 'rememberFor7days'))}>7天内自动登录</Checkbox>
              <span className="cursor text-gray6" onClick={() => setFormType(FORM_TYPE.password)}>忘记密码</span>
            </div>
        }
        <Button className="panel-form-login_button linear-btn" onClick={handleSubmit} type="primary" block loading={loading}>登录</Button>
        <span className="text-center text-gray6">登录免费体验一站式智能图书营销</span>
      </div>
    </div>
  );
}

Login.defaultProps = {};

export default Login;
