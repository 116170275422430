// import config from '@/config';
import { getExtensionFilename, getFileId } from '@/components/FileUpload/FileUtils';

// 环境域名
const ENV_PARAMS = {
  DEV: {
    envCode: 'DEV',
    domain: 'chubanyun.me'
  },
  TEST: {
    envCode: 'TEST',
    domain: 'raysgo.com'
  },
  UAT: {
    envCode: 'UAT',
    domain: 'raysyun.com'
  },
  PROD: {
    envCode: 'PROD',
    domain: '5rs.me'
  }
};

// obsKeys
const obsKeys = {
  DEV: {
    access_key_id: 'TMTKDFCVN0LEU0JGNTJM',
    secret_access_key: '2KSZCysX8YJgE8aQWJuT7kmlnOBcgAJxp5EC7nrO',
    server: 'obs.cn-east-2.myhuaweicloud.com'
  },
  TEST: {
    access_key_id: 'TMTKDFCVN0LEU0JGNTJM',
    secret_access_key: '2KSZCysX8YJgE8aQWJuT7kmlnOBcgAJxp5EC7nrO',
    server: 'obs.cn-east-2.myhuaweicloud.com'
  },
  PROD: {
    access_key_id: 'ROLAKS71MKFKSO4NKDMO',
    secret_access_key: 'ZgL1TVZiOWXWXO2i1tpgZZTI0XvQuniz9gVwEcvG',
    server: 'obs.cn-east-2.myhuaweicloud.com'
  },
  UAT: {
    access_key_id: 'N45I4TP61CRTZSZLBTX7',
    secret_access_key: 'd84O5003ZpZiRcPwyvve5fy1sOoTdt0nAmk1SEDs',
    server: 'obs.cn-east-2.myhuaweicloud.com'
  }
};

export const ACTIVE_ENV = getEnvParams();

const ossConfig = obsKeys[ACTIVE_ENV.envCode];

/**
 *
 * @returns {{envCode: string, domain: string}|{envCode: string, domain: string}|{envCode: string, domain: string}|{envCode: string, domain: string}|{envCode: string, domain: string}}
 */
function getEnvParams() {
  // reading-manage.shubenjia.com
  // https://shubenjia-reading-manage.raysgo.com/
  let envOrigin = window.location.origin;
  let [envParams] = Object.values(ENV_PARAMS).filter(item => {
    return envOrigin.includes(item.domain);
  });
  return envParams ? envParams : ENV_PARAMS.TEST;
}

/**
 * create OBS
 * @param isConvert
 * @returns {*}
 */
export const createOBSClient = (isConvert = false) => {
  if(!createOBSClient.cache) {
    createOBSClient.cache = {};
  }
  const cache = createOBSClient.cache;
  const key = `convert_${isConvert}`;
  if(!cache[key]) {
    cache[key] = new window.ObsClient(Object.assign({}, ossConfig));
  }
  return cache[key];
};

/**
 * OBS BucketName
 * @param isConvert
 * @returns {string}
 */
export const getBucketName = (isConvert = false) => {
  return `whlg-oss-${ACTIVE_ENV.envCode.toLowerCase()}-00${isConvert ? 1 : 2}`;
};

/**
 *  上传文件路径
 * @param file
 * @returns {string}
 */
export const getFileRoute = file => {
  const fileId = getFileId(file);
  const ext = getExtensionFilename(file.name);
  return `oss/uploadfe/${ext}/${fileId}.${ext}`;
};

/**
 * 上传文件url
 * @param file
 * @returns {string}
 */
export const getFileUrl = file => {
  return `https://oss.${ACTIVE_ENV.domain}/${getFileRoute(file)}`;
};
