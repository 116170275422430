/**
 * Date    : 2022/1/26
 * Author  : WeiLin
 * Declare : index
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useThrottleFn } from 'ahooks';

import Image from '@/components/Image';
import PartOne from './VoyagePart/PartOne';
import PartTwo from './VoyagePart/PartTwo';
import PartThree from './VoyagePart/PartThree';
import PartFour from './VoyagePart/PartFour';
import LoginForm from './LoginForm';

import './index.scss';

export default function Login(props) {

  const [scrollY, setScrollY] = useState(0);

  const isInViewport = (element) => {
    const rect = element?.getBoundingClientRect();
    return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  };

  const { run: handleScroll } = useThrottleFn((options) => {
    setScrollY(window.scrollY);
  }, { wait: 50 });

  useEffect(() => {
    window.location.title = '书船';
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const partProps = useMemo(() => {
    return {
      scrollY,
      isInViewport
    };
  }, [scrollY]);

  return (
    <section className="VoyagePage">
      <div className="voyage-content">
        <div className="voyage-header dis-flex">
          <Image className="voyage-logo" src="https://oss.5rs.me/oss/uploadfe/png/1283e5a1ac403c23870a266b681eac1f.png" />
          <div className="voyage-header-tabs dis-flex">
            {/* {
          TABS.map((item, index) => {
            return (
              <span key={index}>{item.title}</span>
            );
          })
        } */}
            <LoginForm key={2} className="login-form-btn" type="LOGIN" content="登录" />
            {/* <LoginForm key={1} /> */}
          </div>
        </div>
        <PartOne {...partProps} />
        <PartTwo {...partProps} />
        <PartThree {...partProps} />
        <PartFour {...partProps} />
      </div>
    </section>
  );
}
