import md5 from 'md5';
import { FORMAT_MAP } from '@/constants/common';

// 文件状态
export const FileStatus = {
  INIT: 1,
  IDLE: 2,
  PROCESSING_QUEUED: 9,
  PROCESSING: 3,
  PROCESSING_COMPLETE: 5, // 完成
  PROCESSING_ERROR: 6, // 失败
  PROCESSING_REVERT_ERROR: 10,
  LOADING: 7,
  LOAD_ERROR: 8
};

/**
 * file To dataURL
 * @param {*} file
 * @param {*} callback
 */
export function blobToBase64(file, callback) {
  // 确保 `file.name` 符合要求的扩展名
  if(/\.(jpe?g|png|gif)$/i.test(file.name)) {

    const reader = new FileReader();

    reader.addEventListener('load', () => {
      callback(reader.result);
    }, false);

    reader.readAsDataURL(file);
  }

}

/**
 * dataURLtoFile
 * @param dataUrl
 * @param fileName
 * @returns {File}
 */
export function dataURLtoFile(dataUrl, fileName) {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1], //mime类型 image/png
    bstr = atob(arr[1]), //base64 解码
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while(n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

// arrayRemove
export const arrayRemove = (arr, index) => arr.splice(index, 1);

// extension filename
export const getExtensionFilename = name => name.split('.').pop();

/**
 * fileId
 * @param file
 * @returns {*}
 */
export const getFileId = (file = {}) => {
  const ext = getExtensionFilename(file.name);
  const lastModified = Number(new Date(file.lastModified || file.lastModifiedDate || ''));
  return md5(String(ext) + lastModified + file.size);
};

/**
 * 媒体信息
 * @param file
 * @returns {Promise<unknown>}
 * mediainfo.js
 */
export const getMediaInfo = (file) => {
  return new Promise(function(resolve, reject) {
    let MediaInfo = window.MediaInfo || null;
    if(MediaInfo) {
      MediaInfo({ format: 'object' }, (mediainfo) => {
        if(file) {
          const getSize = () => file.size;
          const readChunk = (chunkSize, offset) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if(event.target.error) {
                reject(event.target.error);
              }
              resolve(new Uint8Array(event.target.result));
            };
            reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
          });

          mediainfo.analyzeData(getSize, readChunk)
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    }
  });
};

// 通过文件后缀名获取文件类型
export const getTypeByExt = ({ ext = '', fileName = '' }) => {
  const FileExt = String(ext).toLowerCase() || getExtensionFilename(fileName);
  const formatO = FORMAT_MAP(true);
  let type = '';
  if(FileExt) {
    for(let key in formatO) {
      if(Object.prototype.hasOwnProperty.call(formatO, key)) {
        const formatList = formatO[key];
        if(formatList.includes(FileExt)) {
          type = key;
          break;
        }
      }
    }
  }
  return type;
};

// 获取文件名（不带后缀名）
export const getFileName = (fileName = '') => {
  const lastIndex = fileName.lastIndexOf('.');
  let name = fileName;

  if(lastIndex > 0) {
    name = fileName.slice(0, lastIndex);
  }
  return name;
};

/**
 * 检查是否要转码
 * @param file
 */
export const checkConvertByFormatType = async ({ file }) => {
  const NO_CONVERT_TYPE = ['mp3', 'mp4'];
  const fileExt = getExtensionFilename(file.name);
  let type = getTypeByExt({ ext: fileExt, fileName: file.name });
  let hasConvert = true;
  let duration = 0;
  if(NO_CONVERT_TYPE.includes(`${fileExt}`.toLowerCase())) {
    let result = await getMediaInfo(file);
    console.log(result);//不要删
    const { track = [] } = result.media;
    if(type === 'AUDIO') {
      let [mediaInfo = {}] = track.filter((v = {}) => v['@type'] === 'Audio');
      hasConvert = !['MPEG Audio', 'WMA'].includes(mediaInfo.Format);
      duration = mediaInfo.Duration;
    }
    if(type === 'VIDEO') {
      let [mediaInfo = {}] = track.filter((v = {}) => v['@type'] === 'Video');
      hasConvert = !['AVC'].includes(mediaInfo.Format);
      duration = mediaInfo.Duration;
    }
  }
  return { hasConvert, duration };
};

/**
 * 文件大小
 * @param bytes
 * @param decimalSeparator
 * @param base
 * @param options
 * @returns {string}
 */
export const toNaturalFileSize = (bytes, decimalSeparator = '.', base = 1024, options = {}) => {

  const {
    labelBytes = 'bytes',
    labelKilobytes = 'KB',
    labelMegabytes = 'MB',
    labelGigabytes = 'GB'
  } = options;

  // no negative byte sizes
  bytes = Math.round(Math.abs(bytes));

  const KB = base;
  const MB = base * base;
  const GB = base * base * base;

  // just bytes
  if(bytes < KB) {
    return `${bytes} ${labelBytes}`;
  }

  // kilobytes
  if(bytes < MB) {
    return `${Math.floor(bytes / KB)} ${labelKilobytes}`;
  }

  // megabytes
  if(bytes < GB) {
    return `${removeDecimalsWhenZero(bytes / MB, 1, decimalSeparator)} ${labelMegabytes}`;
  }

  // gigabytes
  return `${removeDecimalsWhenZero(bytes / GB, 2, decimalSeparator)} ${labelGigabytes}`;
};

const removeDecimalsWhenZero = (value, decimalCount, separator) => {
  return value.toFixed(decimalCount).split('.').filter(part => part !== '0').join(separator);
};
