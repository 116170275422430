
export const isDevelopment = process.env.NODE_ENV !== 'production';

const hostIP = isDevelopment ? 'localhost' : window.location.hostname; //STEP2

const publicDomain = isDevelopment ? 'raysgo.com' : hostIP.substring(hostIP.indexOf('.') + 1);

const domainArr = ['raysgo.com', 'shubenjia.com'];

const envArr = ['TEST', 'PRO'];
const envIndex = domainArr.findIndex(v => v === publicDomain); // 0测试环境，1生产环境;
export const BASE_API_ROOT = window.location.origin + (isDevelopment ? '/api' : '');

// 系统角色
const systemCode = 'rays_voyage';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  systemCode,
  industryCaseId: systemId,
  systemCodeText: '书船管理平台',
  COOKIE: `${systemCode}_token`,
  env: envArr[envIndex],
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js'     // 字体图标库链接
};

export default config;
