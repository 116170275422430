/**
 * Date    : 2021/12/1
 * Author  : WeiLin
 * Declare : index
 */

import { Switch } from 'antd';

import './index.scss';

export default Switch;
