import React, { useState, useRef } from 'react';
import { useRequest, useDebounceFn } from 'ahooks';

import Message from '@/components/Message';
import Button from '@/components/Button';
import demandapi from '@/api/demandapi';
import FormRender, { FormField } from '@/components/FormRender';
import Icon from '@/components/Icon';
import { regs } from '@/utils/formValidations';

import './index.scss';

function Register(props) {

  const { setFormType, FORM_TYPE, setState } = props;

  const formRef = useRef(null);

  /**state  state部分**/
  const [values, setValues] = useState({});

  const { loading, runAsync: consult } = useRequest(demandapi.consult, {
    manual: true
  });

  const { run: handleSubmit } = useDebounceFn(() => {
    consult(values).then(() => {
      Message.success('资料提交成功！');
      formRef.current.resetFields();
      setState({ isFinish: true });
    });
  }, { wait: 500 });

  function onChange(value, type) {
    const { ...newValues } = values;
    newValues[type] = value;
    setValues(newValues);
  }

  return (
    <div className="Register dis-flex col-flex">
      <div className="panel-title text-weight">免费获取“书船”产品体验资格</div>
      <FormRender className="panel-form dis-flex col-flex" parentRef={formRef} onFinish={handleSubmit}>
        <FormField
          name="reserveName"
          label="您的姓名"
          rules={[
            {
              required: false,
              pattern: VALIDATOR.name,
              validator: (options, value) => {
                if(!value) {
                  return Promise.reject('请输入您的姓名');
                }
                if(!VALIDATOR.name.test(value)) {
                  return Promise.reject('姓名最多为30字');
                }
                return Promise.resolve();
              }
            }
          ]}
          fieldProps={{
            className: 'panel-form-input',
            placeholder: '请输入您的姓名',
            onChange: (value => onChange(value, 'reserveName'))
          }}
        />
        <FormField
          label="您的手机号"
          name="reservePhone"
          rules={[
            {
              required: true,
              pattern: regs.mobile,
              message: '请输入正确手机号'
            }
          ]}
          fieldProps={{
            className: 'panel-form-input',
            placeholder: '请输入您的手机号',
            onChange: (value => onChange(value, 'reservePhone'))
          }}
        />
        <FormField
          label="出版社名称"
          name="reserveAgentName"
          rules={[{ required: true }]}
          fieldProps={{
            className: 'panel-form-input',
            placeholder: '出版社名称',
            onChange: (value => onChange(value, 'reserveAgentName'))
          }}
        />
      </FormRender>
      <div className="dis-flex col-flex register-form-bottom">
        <Button className="register-button linear-btn" onClick={() => formRef.current.submit()} type="primary" block loading={loading}>
          <span>立即获取</span>
          <Icon type="arrow-right" className="f30 text-weight" />
        </Button>
        <div className="panel-actions dis-flex flex-center">
          <span>已有体验账号？</span>
          <span className="text-primary cursor" onClick={() => setFormType(FORM_TYPE.login)}>去登录</span>
        </div>
      </div>
    </div>
  );
}

Register.defaultProps = {};

export default Register;

const VALIDATOR = {
  name: /^.{1,30}$/
};
