import Immutable from 'seamless-immutable';
import userApi from '@/api/userapi';

export default {
  namespace: 'activity',
  state: Immutable({
    gradeList: []
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    getGradeList: function(payload) {
      return {
        type: 'activity/update/gradeList',
        field: 'gradeList',
        promise: userApi.getGradeList(payload)
      };
    }
  }
};

