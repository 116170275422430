/**
 * Date    : 2022/1/20
 * Author  : WeiLin
 * Declare : index
 */

import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import Image from '@/components/Image';
import Modal from '@/components/Modal';

import FilePiker from '@/components/FileUpload/FilePiker';

import './index.scss';

export default function FileUpload({ value, onChange, ...props }) {

  const { children, fileRef, className, placeholder, maxFiles, loadLabel } = props;

  const fileNode = useRef(null);

  useImperativeHandle(fileRef, () => fileNode.current);

  /**state  state部分**/
  const [fileValues, setFileValues] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);

  const [previewImage, setPreviewImage] = useState({});

  /**effect  effect部分**/
  useEffect(() => {
    if(!value) return;
    let arr = [];
    if(typeof value === 'string') {
      arr = [{ fileUrl: value }];
    }
    if(value instanceof Array) {
      arr = value.map(item => Object.assign({}, { fileUrl: item }));
    }
    setFileValues(arr);
  }, []);

  /**render**/
  const addFile = () => {
    fileNode.current && fileNode.current.addFile();
  };

  const triggerChange = (changedValue) => {
    setFileValues(changedValue);

    let valueFile = changedValue.map(item => item.fileUrl);

    if(valueFile.length <= 1) {
      valueFile = valueFile[0];
    }

    onChange && onChange(valueFile);

  };

  const uploadView = () => {
    return (
      <span className="upload-input" onClick={addFile}>
        <PlusOutlined />
        <span className="mt5 text-gray3 nowrap">{ loadLabel }</span>
      </span>
    );
  };

  function handlePreview(item) {
    setPreviewImage(item);
    setPreviewVisible(true);
  }

  function deleteImage(item) {
    setFileValues([]);
    onChange && onChange();
  }

  return (
    <FilePiker fileRef={fileNode} type="ALBUM" initialValue={fileValues} onChange={triggerChange}>
      <div className={`dis-flex upload-wrap ${className}`}>
        <div className="upload">
          {
            !!fileValues?.length &&
            <span className="upload-item">
              {
                fileValues?.map(item => {
                  return (
                    <div className="upload-item-image" key={item.fileId}>
                      <Image src={item.fileUrl} />
                      <div className="upload-item-action dis-flex">
                        <EyeOutlined className="action-icon mui-icon" onClick={() => handlePreview(item)} />
                        <DeleteOutlined className="action-icon delete-icon" onClick={() => deleteImage(item)} />
                      </div>
                    </div>
                  );
                })
              }
            </span>
          }
          { fileValues.length < maxFiles && (children || uploadView()) }
        </div>

        { placeholder && <span className="text-gray upload-placeholder"> { placeholder } </span> }

        <Modal title={previewImage.fileName} width={520} open={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
          <img alt="example" style={{ width: '100%' }} src={previewImage.fileUrl} />
        </Modal>

      </div>
    </FilePiker>
  );
}

FileUpload.defaultProps = {
  className: '',
  placeholder: '',
  loadLabel: '上传图片',
  maxFiles: 1
};
