/**
 * Date    : 2021/11/19
 * Author  : WeiLin
 * Declare : index
 */

import React from 'react';
import classNames from 'classnames';

import './index.scss';

export default function Label({ title, className = '', required, children }) {
  return (
    <label className={`label ${className}`}>
      {
        title && <span className={classNames(`label-title`, { 'required': required })}>{title}</span>
      }
      {children}
    </label>
  );
}
