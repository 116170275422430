import React from 'react';

import FormEditor from '@/components/FormRender/QEditor/formEditor';
import FileUpload from '@/components/FileUpload';
import DatePicker, { RangePicker, TimePicker } from '@/components/FormRender/Pickers';
import Input from '@/components/FormRender/Input';
import RadioGroup from '@/components/FormRender/RadioGroup';
import CheckboxGroup from '@/components/FormRender/Checkbox';
import Select from '@/components/FormRender/Select';
import Switch from '@/components/FormRender/Switch';
import FilePiker from '@/components/FileUpload/FilePiker';

export const COMMON_FIELDS = {
  input: props => <Input {...props} />,
  number: props => <Input.Number {...props} />,
  password: props => <Input.Password {...props} />,
  textArea: props => <Input.TextArea {...props} />,
  editor: props => <FormEditor {...props} />,
  imageUpload: props => <FileUpload {...props} />,
  filepick: props => <FilePiker {...props} />,
  radio: props => <RadioGroup {...props} />,
  checkBox: props => <CheckboxGroup {...props} />,
  select: props => <Select {...props} />,
  switch: props => <Switch {...props} />,
  rangePiker: props => <RangePicker {...props} />,
  datePicker: props => <DatePicker {...props} />,
  timePicker: props => <TimePicker {...props} />
};
