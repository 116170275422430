/**
 * Date    : 2022/1/12
 * Author  : WeiLin
 * Declare : index
 */

import React, { useEffect, useImperativeHandle, useMemo, useState, useRef } from 'react';
import { useMount, useUnmount, useCounter } from 'ahooks';

import { blobToBase64 } from '@/components/FileUpload/FileUtils';
import { FILE_ACCEPT } from '@/constants/common';
import { EventEmitter, EVENT_NAMES } from '@/utils/eventEmitter';
import OBSUploader from '@/components/FileUpload/OBSUpload';
import Message from '@/components/Message';

export default function FilePiker(props) {

  const { className, fileRef, children, initialValue, onChange, options, type, pikerText, sizeLimit } = props;

  // file element
  const filePiker = useRef(null);

  // drop element
  const pikerDrop = useRef(null);

  // event
  const _eventEmitter = useMemo(() => {
    return new EventEmitter();
  }, []);

  const [files, setFiles] = useState([]);

  const [uploadTimes, { inc: addUploadTimes }] = useCounter(0);

  useEffect(() => {
    setFiles(initFileValue(initialValue));
  }, [initialValue]);

  // export 方法
  useImperativeHandle(fileRef, () => ({
    filePiker: filePiker.current, // return element
    fileEvent: _eventEmitter,
    addFile
  }));

  /**state  state部分**/
  const acceptValue = useMemo(() => {
    const { accept } = FILE_ACCEPT()[type] || {};
    return accept || '';
  }, [type]);

  /**effect  effect部分**/
  const mergeOptions = useMemo(() => {
    const FILE_CONFIG = {
      id: 'file',
      name: 'file',
      accept: acceptValue,
      maxFiles: '',
      required: false,
      multiple: false,
      disabled: false
    };
    return Object.assign(FILE_CONFIG, options);
  }, [options]);

  /**
   * 视图区
   * @returns {*}
   */
  function renderView() {
    const viewChild = children || <span className="cursor">{ pikerText }</span>;
    return (
      <div className="file-pool--root" onClick={addFile}>{ viewChild }</div>
    );
  }

  /**
   * 更新 state
   * @param values
   */
  function syncSetFiles(values) {
    setFiles((prevState) => {
      const [...newValues] = prevState;

      const updateIndex = newValues.findIndex(item => item.fileId === values.fileId);

      if(newValues[updateIndex]) {
        const updateItem = newValues[updateIndex] || {};

        newValues[updateIndex] = Object.assign(updateItem, values);

        onChange && onChange(newValues);
      }

      return newValues;
    });
  }

  /**
   * add file
   */
  function addFile() {
    return new Promise((resolve, reject) => {
      if(!filePiker.current) return;
      filePiker.current.click();
      resolve(true);
    });
  }

  /**
   *  select files
   * @param e
   */
  function selectFile(e) {
    if(!e.target.files) return;

    addUploadTimes();

    let [...newFiles] = e.target.files;

    let errorMsg = '';

    const accept = FILE_ACCEPT()[type]?.accept || '';

    if(mergeOptions.maxFiles) {
      if(e.target.files.length > mergeOptions.maxFiles) {
        errorMsg = `最多只能上传${mergeOptions.maxFiles}个文件`;
      }
    }
    newFiles.map(v => {
      let size = v.size;
      let ext = v.type;
      if(sizeLimit && sizeLimit < (size / 1024)) {
        errorMsg = `上传的文件过大，上传的文件大小不能超过${sizeLimit >= 1024 ? Math.floor(sizeLimit / 1024) + 'M' : sizeLimit + 'KB'}!`;
      }
      if(accept && type && !accept.split(', ').includes(ext)) {
        errorMsg = `请上传${FILE_ACCEPT()[type].name} ext`;
      }
    });

    if(errorMsg) {
      return Message.error(errorMsg);
    }

    return new Promise((resolve, reject) => {

      // create file
      newFiles = initFileValue(newFiles);

      setFiles(newFiles);

      newFiles.forEach(item => {
        // 生成base64、上传图片
        blobToBase64(item.file, (value) => {
          syncSetFiles(Object.assign(item, { fileUrl: value }));
        });
        // 上传文件
        item.upload();
      });

      resolve(newFiles);

    });
  }

  // 初始化文件对象
  function initFileValue(values = []) {
    let [...files] = values;
    return files.map(file => {
      if(file instanceof Blob) {
        return new OBSUploader({
          file: file,
          config: {
            fail: (error) => {
              console.log(error, '上传失败');
            },
            progress: (values) => {
              syncSetFiles(values);
            },
            success: (values) => {
              syncSetFiles(values);
            }
          }
        });
      } else {
        return Object.assign({}, file);
      }
    });
  }

  /**
   * reset file
   */
  function resetFile() {
    setFiles([]);
  }

  useMount(() => {
    // add event
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_ADD`, addFile);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_UPDATE`, syncSetFiles);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_SELECT`, selectFile);
    _eventEmitter.on(`${EVENT_NAMES.FILE_EVENT}_RESET`, resetFile);
  });

  useUnmount(() => {
    resetFile();
  });

  return (
    <div ref={pikerDrop} className={`FilePiker ${className}`}>
      { renderView() }
      <input key={uploadTimes} ref={filePiker} type="file" {...mergeOptions} style={{ display: 'none' }} onChange={selectFile} />
    </div>
  );
}

FilePiker.defaultProps = {
  className: '',
  action: '',
  options: {}, // maxFiles、required、multiple、disabled
  type: '', // 文件类型
  pikerText: '文件上传',
  allowDrop: false
};
