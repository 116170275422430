import Loadable from '@loadable/component';
import Message from '@/components/Message';

import { createRouter } from '@/utils/router';
import * as Cookie from '@/utils/cookie';
import PAGE from '@/constants/page';
import Loading from '@/components/Loading';
import userapi from '@/api/userapi';
import spider from '@/business/spider';

import App from './App';

export default createRouter({
  getComponent: () => App,
  onEnter: async (nextState, history, cb) => {
    const { location, match, query } = nextState;
    const cookie = Cookie.getCookie();
    const isMatch = match && match.isExact;
    spider.init();
    // 已登录
    if(query.name && query.pwd) {
      userapi.login({
        username: decodeURIComponent(query.name),
        password: query.pwd
      })
        .then(res => {
          Cookie.saveCookie(res.token);
          Message.success('登录成功');
        })
        .catch(() => {
          history.replace(PAGE.LOGIN);
        })
        .finally(() => {
          cb();
        });
    } else if(cookie) {
      // index route
      if(isMatch) {
        history.replace(PAGE.HOME);
      }
      cb();
    } else {    // 未登录
      if(location.pathname.startsWith('/main') || isMatch) {
        history.replace(PAGE.LOGIN);
      }
      cb();
    }
  },
  getChildRoutes: () => [
    {
      path: '/adviser',
      component: Loadable(() => import('./pages'), { fallback: Loading })
    },
    {
      component: Loadable(() => import('./404'), { fallback: Loading })
    }
  ]
});
