/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : Select
 */

import React, { useMemo } from 'react';
import { Select as AndSelect } from 'antd';

import Utils from '@/utils/utils';

export default function Select(props) {

  const { className, options, width, textKey = 'label', valueKey = 'value', defaultValue, ...restProps } = props;

  // 处理为空不显示placeholder的情况
  const initValues = useMemo(() => {
    return defaultValue || null;
  }, [defaultValue]);

  return (
    <AndSelect style={{ width }} className={`select ${className}`} defaultValue={initValues} {...restProps}>
      {
        options.map((ops, index) => {
          const { option = {}, optionProps }  = Utils.handlerOptions(ops, textKey, valueKey);
          return (
            <AndSelect.Option
              className="select-option"
              key={index}
              value={option[valueKey]}
              {...optionProps}
            >
              {option[textKey]}
            </AndSelect.Option>
          );
        })
      }
    </AndSelect>
  );
}

Select.defaultProps = {
  className: '',
  defaultValue: '',
  width: 200,
  options: []
};
