export const regs = {
  url:
    '^(http|https|ftp)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]',
  email: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
  mobile: /^1[3|4|5|6|7|8|9][0-9]{9}$/,
  //mobile: /^\d{1,17}$/,
  idCard: /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/,
  currency: '^d+(.d+)?$',
  qq: '^[1-9]d{4,8}$',
  number: '^[1-9][0-9]*$',
  positiveInt: '^[1-9]d*$',
  zip: '^[1-9]d{5}$',
  double: '^[-+]?d+(.d+)?$',
  english: '^[A-Za-z]+$',
  chinese: /^[\u4e00-\u9fa5]+$/,
  unSafe: '^(.{0,5})$|s',
  phone: /^\d{1,17}$/,
  time: /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/,
  date:
    '(([0-9]{3}[1-9]|[0-9]{2}[1-9][0-9]{1}|[0-9]{1}[1-9][0-9]{2}|[1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([0-9]{2})(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29)',
  testCode: /^\d{4}$/,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
  passwordReg: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
};

export const defaultPassword = ['123456', 'Aa123456', 'Ab654312'];