import user from './user';
import projectSetting from './project-setting';
import common from './common';
import activity from './activity';

export default [
  user,
  common,
  projectSetting,
  activity
];
