import React from 'react';

import './index.scss';

function Finish(props) {

  return (
    <div className="Finish dis-flex flex-center">
      <div className="finish-content dis-flex col-flex flex-center">
        <img className="finish-icon" src="https://oss.5rs.me/oss/uploadfe/png/c75baa2e6826d243d6546f0f1c9ee882.png" alt="" />
        <div className="finish-title">申请体验资格已提交</div>
        <div className="finish-subtitle text-center">
          <div>服务人员会尽快与您联系确认需求，请保持电话畅通</div>
          <div>您也可以添加书船专属客服，获取最新产品动态</div>
        </div>
        <img className="finish-code" src="https://oss.5rs.me/oss/uploadfe/png/4c77a24037f43fcf2c7fd6f9f0f22780.png" alt="" />
      </div>
    </div>
  );
}

Finish.defaultProps = {

};

export default Finish;
