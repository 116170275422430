/**
 *  Date    : 2021/11/18
 *  Author  : WeiLin
 *  Declare : 通用常量
 */

// 环境标识
export const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  UAT: 'UAT',
  PRO: 'PRO'
};

export const TOKEN = 'TOKEN';

export const PORTAL_ID = {
  BOTTOM: 'PORTAL_BOTTOM'
};

export const EMPTY_PAGE_BEAN = {
  recordList: []
};

//表图数据初始值
export const EMPTY_CHART_DATA = {
  values: [[]],
  axis: [],
  axisSort: []
};

export const NOOP = () => {};   // 无操作，常用于props缺省值
export const JUST_RETURN = _ => _;    // 直接返回
export const RETURN_EMPTY_OBJECT = () => ({});    // 返回空对象，常用于connect传递空的mapStateToProps
export const PREVENT_DEFAULT = event => event.preventDefault();
export const STOP_PROPAGATION = event => event.stopPropagation();

export const FILE_TYPE = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  EXCEL: 'EXCEL',
  PDF: 'PDF',
  WORD: 'WORD',
  PPT: 'PPT',
  ZIP: 'ZIP',
  TXT: 'TXT',
  ALBUM: 'ALBUM'
};

export const FILE_ICON = {
  PDF: 'PDF-icon',
  WORD: 'WORD-icon',
  EXCEL: 'Excel-icon',
  ALBUM: 'picture'
};

export const GET_FILE_TYPE = (file) => {
  const formatMap = FORMAT_MAP();
  let fileKey = '';
  if(Object.keys(formatMap).includes(file?.fileType)) {
    fileKey = file?.fileType;
  } else {
    fileKey = Object.keys(formatMap).find(key => formatMap[key].includes(file?.fileType || file?.ext));
  }
  return fileKey;
};

export const FORMAT_MAP = (convert = true) => ({
  AUDIO: convert ? ['mp3', 'wav', 'aac', 'wma', 'ape', 'amr'] : ['mp3'],
  VIDEO: convert
    ? ['mp4', 'mkv', 'mpg', 'avi', 'asx', 'mov', 'flv', 'rm', 'wmv', 'rmvb']
    : ['mp4'],
  ALBUM: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp'],
  PDF: ['pdf'],
  LYRIC: ['lrc'],
  EXCEL: ['csv', 'xls', 'xlsx', 'xlsm', 'xltm', 'xlam'],
  WORD: ['doc', 'docx'],
  PPT: ['ppt', 'pptx'],
  ZIP: ['zip', 'rar'],
  TXT: ['txt']
});

export const FILE_ACCEPT = (needConvert = true) => ({
  PDF: {
    accept: 'application/pdf',
    name: 'PDF',
    tip: ''
  },
  AUDIO: {
    accept: needConvert
      ? 'audio/mp3, audio/wav, audio/aac, audio/x-ms-wma, audio/x-monkeys-audio, audio/amr'
      : 'audio/mp3',
    name: '音频',
    tip: needConvert ? '上传的音频将自动转换为mp3格式。' : '音频文件支持MP3格式。'
  },
  VIDEO: {
    accept: needConvert
      ? 'video/mp4, video/x-matroska, video/mpeg, video/x-msvideo, video/quicktime, video/x-flv, application/vnd.rn-realmedia, video/x-ms-wmv, application/octet-stream'
      : 'video/mp4, video/x-matroska',
    name: '视频',
    tip: needConvert ? '上传的视频将自动转换为mp4格式。' : '视频文件支持MP4格式。'
  },
  ALBUM: {
    accept: 'image/png, image/gif, image/jpeg',
    name: '图片',
    tip: ''
  },
  LYRIC: {
    accept: '',
    name: '歌词',
    tip: '歌词文件支持LRC格式。'
  },
  EXCEL: {
    accept:
      'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'EXCEL表格',
    tip: '文件格式支持csv、xls、xlsx、xlsm、xltm、xlam格式'
  },
  ZIP: {
    accept: 'application/zip, application/x-rar-compressed',
    name: '压缩文件',
    tip: '压缩文件支持ZIP、RAR格式。'
  },
  'AUDIO-TXT': {
    accept: 'text/plain',
    name: '音频原文',
    tip: '音频原文文件支持TXT格式。'
  },
  WORD: {
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'WORD文档',
    tip: '文件格式支持doc、docx格式'
  },
  PPT: {
    accept:
      'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'PPT文档',
    tip: '文件格式支持ppt、pptx格式'
  },
  EBOOK: {
    accept: 'epub',
    name: '电子书',
    tip: '文件格式支持epub格式'
  },
  WORD_EXCEL_PDF_ALBUM: {
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, image/png, image/gif, image/jpeg',
    name: 'pdf、word、excel、图片、文档格式',
    tip: '文件格式支持pdf、doc、docx、txt格式'
  }
});

export const DEFAULT_AVATAR = {
  ERROR: '',
  USER: 'https://r.5rs.me/image/920b35ee-4e66-4486-8963-6548279381e1/0cd6b9c35a982fdcd008bbba386c568f.png'
};
