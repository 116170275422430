import React, { useImperativeHandle, useMemo, useState } from 'react';
import { useSetState, useUnmount } from 'ahooks';
import classNames from 'classnames';

import Carousel from '@/components/Carousel';
import ModalBase from '@/components/Modal';
import Image from '@/components/Image';

import Login from './Login';
import Register from './Register';
import Password from './Password';
import Finish from './Finish';

import './index.scss';

function LoginForm(props) {

  const { className, content, formRef, type } = props;

  // const [formType, setFormType] = useLocalStorageState('FORM_TYPE', {
  //   defaultValue: FORM_TYPE.registry
  // });

  /**state  state部分**/
  const [formType, setFormType] = useState(type || FORM_TYPE.registry);

  const [state, setState] = useSetState({
    visible: false,
    isFinish: false
  });

  useImperativeHandle(formRef, () => ({
    showModel // return element
  }));

  const closeModel = () => {
    setState({ isFinish: false, visible: false });
    setFormType(type || FORM_TYPE.registry);
    document.body.classList.remove('no-scroll');
  };

  const showModel = () => {
    setState({ visible: true });
    document.body.classList.add('no-scroll');
  };

  const formProps = useMemo(() => {
    return {
      FORM_TYPE,
      setFormType,
      setState,
      type
    };
  }, []);

  useUnmount(() => {
    document.body.classList.remove('no-scroll');
  });

  return (
    <div className={classNames('LoginForm', className)} id="PanelForm">
      <div className="form-trigger cursor" onClick={showModel}>{content}</div>
      <ModalBase
        className="LoginFormModal"
        title=""
        width={950}
        open={state.visible}
        footer={null}
        destroyOnClose
        getContainer={() => document.getElementById('PanelForm')}
        closable={[FORM_TYPE.registry, FORM_TYPE.login].includes(formType)}
        // maskClosable={false}
        style={{ top: '20vh' }}
        onCancel={closeModel}
      >
        {
          !state.isFinish &&
          <div className="form-panel dis-flex">
            <div className="form-panel-banner">
              <Carousel autoplay dots={null}>
                {
                  bannerList.map(item => {
                    return <Image key={item.id} width="100%" className="banner-item" src={item.img} />;
                  })
                }
              </Carousel>
            </div>
            <div className="form-panel-content">
              { formType === FORM_TYPE.registry && <Register {...formProps} />}
              { formType === FORM_TYPE.login && <Login {...formProps} />}
              { formType === FORM_TYPE.password && <Password {...formProps} />}
            </div>
          </div>
        }
        { state.isFinish && <Finish />}
      </ModalBase>
    </div>
  );
}

LoginForm.defaultProps = {
  content: '联系咨询'
};

export default LoginForm;

const FORM_TYPE = {
  registry: 'REGISTRY',
  login: 'LOGIN',
  password: 'PASSWORD'
};

const bannerList = [
  {
    id: 0,
    img: 'https://oss.5rs.me/oss/uploadfe/png/ee8c1e9ba51c4b671e24622992e55ebe.png'
  }
];

