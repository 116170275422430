import  spider from '@wtdc/spider/umd/web_spider.min';

import { BASE_API_ROOT } from '@/config';

spider.setPara({
  url: BASE_API_ROOT + '/commoncenter/v1.0/user/track/report',
  entry: {
    entryType: 'READER_MANAGE',
    entryVersion: '1.0.0'
  }
});

// 后续请直接调用 addTrack 方法
export function addClickTrack(spm, subSpm) {
  return addTrack(TRACK_TYPE.CLICK, spm, subSpm);
}

const tracker = (type, spm, subSpm, extra) => {
  if(extra && extra.constructor !== Object) {
    return Promise.reject('extra is not Object');
  }
  return spider.track(type, {
    contentExtra: Object.assign({
      spm,
      subSpm
    }, extra)
  });
};

export function addTrack(type, spm, subSpm) {
  let args = [].slice.call(arguments);
  if(args.length === 1 && args[0].constructor === Object) {
    const { type, spm, subSpm, ...extra } = args[0];
    return tracker(type, spm, subSpm, extra);
  } else {
    return tracker(type, spm, subSpm);
  }
}

export const TRACK_TYPE = {
  'BROWSE': 'BROWSE',
  'CLICK': 'CLICK',
  'SCAN': 'SCAN',
  'SEARCH': 'SEARCH',
  'ORDER': 'ORDER',
  'PAY_OK': 'PAY_OK',
  'SHARE': 'SHARE',
  'SHARE_OPEN': 'SHARE_OPEN',
  'LOGIN': 'LOGIN',
  'KEEP_BEAT': 'KEEP_BEAT',
  'ENTER': 'ENTER'
};

export default spider;
