import React, { useEffect, useRef } from 'react';

import Image from '@/components/Image';

import LoginForm from '../../LoginForm';

import './index.scss';
function PartFour(props) {

  const { isInViewport } = props;

  const formRef = useRef(null);

  useEffect(() => {
    const viewNode = document.querySelector('.voyage-part-four');
    console.log(isInViewport(viewNode), 'four');
  }, []);

  return (
    <div className="voyage-part voyage-part-four dis-flex col-flex flex-center">
      <div className="voyage-part-logo cursor" onClick={() => formRef?.current?.showModel()}>
        <Image src="https://oss.5rs.me/oss/uploadfe/png/ba9a96df117f609368c0bc769f7ac455.png" alt="" />
      </div>
      <LoginForm formRef={formRef} />
    </div>
  );
}

PartFour.defaultProps = {};

export default PartFour;