/**
 * Date    : 2021/11/18
 * Author  : WeiLin
 * Declare : index
 */

import React from 'react';
import { Checkbox } from 'antd';

import Utils from '@/utils/utils';

export default function CheckboxGroup(props) {

  const { options = [], textKey = 'title', valueKey = 'value', children, ...restProps } = props;

  if(!options.length && children) {
    return <Checkbox {...restProps}>{children}</Checkbox>;
  }

  return (
    <Checkbox.Group {...restProps}>
      {
        options.map((ops, index) => {
          const { option = {}, optionProps } = Utils.handlerOptions(ops, textKey, valueKey);
          return (
            <Checkbox className="checkbox" key={index} value={option[valueKey]} {...optionProps}>
              {option[textKey]}
            </Checkbox>
          );
        })
      }
    </Checkbox.Group>
  );
}

CheckboxGroup.defaultProps = {
  options: []
};
