/**
 * Date    : 2021/11/22
 * Author  : WeiLin
 * Declare : BaseInput
 */

import React from 'react';

import { Input, InputNumber  } from 'antd';

function BaseInput(type) {

  return function(props) {
    const { onChange, ...resProps } = props;

    function handleChange(e) {
      onChange && onChange(e.target.value);
    }

    let Component = Input;

    if(type && Input[type]) {
      Component = Input[type];
    }

    return (
      <Component onChange={handleChange} onPressEnter={handleChange} {...resProps} />
    );
  };
}

const Component = function(props) {
  return BaseInput()(props);
};

Component.Search = function(props) {
  return BaseInput('Search')(props);
};

Component.TextArea = function(props) {
  return BaseInput('TextArea')(props);
};

Component.Password = function(props) {
  return BaseInput('Password')(props);
};

Component.Group = Input.Group;

Component.Number = InputNumber;

export default Component;
